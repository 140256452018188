import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { Country, State, City } from "country-state-city";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class TenantAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      tenant: [
        {
          country: "India",
          state: "Bangalore",
        },
      ],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddTenantInDB = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstName: this.state.tenant.firstName,
        lastName: this.state.tenant.lastName,
        emailId: this.state.tenant.emailId,
        password: this.state.tenant.password,
        contactNo: this.state.tenant.contactNo,
        businessName: this.state.tenant.businessName,
        gstin: this.state.tenant.gstin,
        businessPanNumber: this.state.tenant.businessPanNumber,
        address: this.state.tenant.address,
        city: this.state.tenant.city,
        state: this.state.tenant.state,
        country: this.state.tenant.country,
        businessContactNo1: this.state.tenant.businessContactNo1,
        businessContactNo2: this.state.tenant.businessContactNo2,
        businessEmailId: this.state.tenant.businessEmailId,
        created: new Date().toLocaleString(),
        modified: new Date().toLocaleString(),
      }),
    };

    fetch(process.env.REACT_APP_API + "Sales/AddTenant/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.context.GetTenants();
        this.HideLoadSpinner();
        this.ShowMessagBox("Information", "Added Successfully");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Unable to Add! try later");
        alert("Failed" + error);
      });
  };
  UpdateTenantInDB = () => {
    this.ShowLoadSpinner();
    var tmpProd = this.props.tenant;
    tmpProd.firstName = this.state.tenant.firstName;
    tmpProd.lastName = this.state.tenant.lastName;
    tmpProd.emailId = this.state.tenant.emailId;
    tmpProd.password = this.state.tenant.password;
    tmpProd.contactNo = this.state.tenant.contactNo;
    tmpProd.businessName = this.state.tenant.businessName;
    tmpProd.gstin = this.state.tenant.gstin;
    tmpProd.businessPanNumber = this.state.tenant.businessPanNumber;
    tmpProd.address = this.state.tenant.address;
    tmpProd.city = this.state.tenant.city;
    tmpProd.state = this.state.tenant.state;
    tmpProd.country = this.state.tenant.country;
    tmpProd.businessContactNo1 = this.state.tenant.businessContactNo1;
    tmpProd.businessContactNo2 = this.state.tenant.businessContactNo2;
    tmpProd.businessEmailId = this.state.tenant.businessEmailId;
    tmpProd.modified = new Date().toLocaleString();

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpProd),
    };

    fetch(process.env.REACT_APP_API + "Sales/UpdateTenant/", requestOptions)
      .then((res) => {
        if (res.status == 200) {
          //TODO
          this.context.GetTenants();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Updated Successfully");
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Failed", "Not Updated");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Not Updated");
      });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.props.Type == "Edit") {
      this.UpdateTenantInDB();
    }
    if (this.props.Type == "Add") this.AddTenantInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "firstName":
        this.state.tenant.firstName = value;
        break;
      case "lastName":
        this.state.tenant.lastName = value;
        break;
      case "emailId":
        this.state.tenant.emailId = value;
        break;
      case "password":
        this.state.tenant.password = value;
        break;
      case "contactNo":
        this.state.tenant.contactNo = value;
        break;
      case "businessName":
        this.state.tenant.businessName = value;
        break;
      case "gstin":
        this.state.tenant.gstin = value;
        break;
      case "businessPanNumber":
        this.state.tenant.businessPanNumber = value;
        break;
      case "address":
        this.state.tenant.address = value;
        break;
      case "city":
        this.state.tenant.city = value;
        break;
      case "state":
        this.state.tenant.state = value;
        break;
      case "country":
        this.state.tenant.country = value;
        break;
      case "businessContactNo1":
        this.state.tenant.businessContactNo1 = value;
        break;
      case "businessContactNo2":
        this.state.tenant.businessContactNo2 = value;
        break;
      case "businessEmailId":
        this.state.tenant.businessEmailId = value;
        break;
    }
    this.setState({ tenant: this.state.tenant });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ tenant: this.props.tenant });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="TenantModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit Tenant" : "Add Tenant"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      First Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={this.state.tenant.firstName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Last Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={this.state.tenant.lastName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Email-Id
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="email"
                        name="emailId"
                        value={this.state.tenant.emailId}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Password
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="password"
                        name="password"
                        value={this.state.tenant.password}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Contact No
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="contactNo"
                        value={this.state.tenant.contactNo}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Address
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        name="address"
                        rows={2}
                        value={this.state.tenant.address}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Country
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="country"
                        value={this.state.tenant.country}
                        required
                        placeholder="select Brand"
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value={0}></option>
                        {Country.getAllCountries().map((country) => (
                          <>
                            <option value={country.name}>{country.name}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      State
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="state"
                        value={this.state.tenant.state}
                        required
                        placeholder="select Brand"
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value={0}></option>
                        {State.getStatesOfCountry("IN").map((state) => (
                          <>
                            <option>{state.name}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      City
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="city"
                        value={this.state.tenant.city}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6} className="">
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Business Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="businessName"
                        value={this.state.tenant.businessName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      GSTIN
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="gstin"
                        value={this.state.tenant.gstin}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Business PAN Number
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="businessPanNumber"
                        value={this.state.tenant.businessPanNumber}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Business ContactNo1
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="businessContactNo1"
                        value={this.state.tenant.businessContactNo1}
                        required
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Business ContactNo2
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="businessContactNo2"
                        value={this.state.tenant.businessContactNo2}
                        required
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Business Email-Id
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="email"
                        name="businessEmailId"
                        value={this.state.tenant.businessEmailId}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
