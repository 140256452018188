import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import DatePicker from "react-date-picker";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class StoreAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      store: [
        {
          country: "India",
          state: "Bangalore",
        },
      ],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      storeShortName: "",
      enterpriseStores: [],
    };
  }

  handleAddEnterpriseStores = (e) => {
    this.setState({
      enterpriseStores: [
        ...this.state.enterpriseStores,
        {
          childStoreId: "",
          fileName: "",
          location: "",
          pincodes: "",
        },
      ],
    });
  };
  handleEnterpriseStoresSelectionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.enterpriseStores];
    list[index][name] = value;
    this.setState({ enterpriseStores: list });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddStoreInDB = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        tenantId: this.state.store.tenantId,
        storeTypeId: this.state.store.storeTypeId,
        name: this.state.store.name,
        businessTypeId: this.state.store.businessTypeId,
        description: this.state.store.description,
        storeStatusId: this.state.store.storeStatusId,
        isTrail: this.state.store.isTrail,
        monthlyCost: this.state.store.monthlyCost,
        monthlyCostDiscount: this.state.store.monthlyCostDiscount,
        transactionCharges: this.state.store.transactionCharges,
        storeSetupCost: this.state.store.storeSetupCost,
        subscriptionDetails: this.state.store.subscriptionDetails,
        storeStart: this.state.store.storeStart,
        storeEnd: this.state.store.storeEnd,
        storeDomainName: this.state.store.storeDomainName,
        storeAdminDomainName: this.state.store.storeAdminDomainName,
        storePosDomainName: this.state.store.storePosDomainName,
        storageBlobUrl: this.state.store.storageBlobUrl,
        storageBlobContainerName: this.state.store.storageBlobContainerName,
        storageSasToken: this.state.store.storageSasToken,
        invoiceFileShareAccountUrl: this.state.store.invoiceFileShareAccountUrl,
        storageConnectionString: this.state.store.storageConnectionString,
        invoiceFileShareName: this.state.store.invoiceFileShareName,
        enterpriseStores: this.state.enterpriseStores,
        created: new Date().toLocaleString(),
        modified: new Date().toLocaleString(),
      }),
    };

    fetch(process.env.REACT_APP_API + "Sales/AddStore/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          this.ShowMessagBox("Error", "Save Failed. try later!");
          return;
        }
        this.context.GetStores();
        this.HideLoadSpinner();
        this.ShowMessagBox("Information", "Added Successfully");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Unable to Add! try later");
        alert("Failed" + error);
      });
  };
  UpdateStoreInDB = () => {
    this.ShowLoadSpinner();
    var tmpstore = this.props.store;
    tmpstore.tenantId = this.state.store.tenantId;
    tmpstore.storeTypeId = this.state.store.storeTypeId;
    tmpstore.name = this.state.store.name;
    tmpstore.businessTypeId = this.state.store.businessTypeId;
    tmpstore.description = this.state.store.description;
    tmpstore.storeStatusId = this.state.store.storeStatusId;
    tmpstore.isTrail = this.state.store.isTrail;
    tmpstore.monthlyCost = this.state.store.monthlyCost;
    tmpstore.monthlyCostDiscount = this.state.store.monthlyCostDiscount;
    tmpstore.transactionCharges = this.state.store.transactionCharges;
    tmpstore.storeSetupCost = this.state.store.storeSetupCost;
    tmpstore.subscriptionDetails = this.state.store.subscriptionDetails;
    tmpstore.storeStart = this.state.store.storeStart;
    tmpstore.storeEnd = this.state.store.storeEnd;
    tmpstore.storeDomainName = this.state.store.storeDomainName;
    tmpstore.storeAdminDomainName = this.state.store.storeAdminDomainName;
    tmpstore.storePosDomainName = this.state.store.storePosDomainName;
    tmpstore.storageBlobUrl = this.state.store.storageBlobUrl;
    tmpstore.storageBlobContainerName =
      this.state.store.storageBlobContainerName;
    tmpstore.storageSasToken = this.state.store.storageSasToken;

    tmpstore.invoiceFileShareAccountUrl =
      this.state.store.invoiceFileShareAccountUrl;
    tmpstore.storageConnectionString = this.state.store.storageConnectionString;
    tmpstore.invoiceFileShareName = this.state.store.invoiceFileShareName;
    tmpstore.modified = new Date().toLocaleString();
    tmpstore.enterpriseStores = this.state.enterpriseStores;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpstore),
    };

    fetch(process.env.REACT_APP_API + "Sales/UpdateStore/", requestOptions)
      .then((res) => {
        if (res.status == 200) {
          this.context.GetStores();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Updated Successfully");
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Failed", "Not Updated");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Not Updated");
      });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.props.Type == "Edit") {
      this.UpdateStoreInDB();
    }
    if (this.props.Type == "Add") this.AddStoreInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "tenantId":
        this.state.store.tenantId = value;
        break;
      case "storeTypeId":
        this.state.store.storeTypeId = value;
        break;
      case "businessTypeId":
        this.state.store.businessTypeId = value;
        break;
      case "name":
        this.state.store.name = value;
        break;
      case "description":
        this.state.store.description = value;
        break;
      case "storeStatusId":
        this.state.store.storeStatusId = value;
        break;
      case "isTrail":
        this.state.store.isTrail = value;
        break;
      case "monthlyCost":
        this.state.store.monthlyCost = value;
        break;
      case "monthlyCostDiscount":
        this.state.store.monthlyCostDiscount = value;
        break;
      case "transactionCharges":
        this.state.store.transactionCharges = value;
        break;
      case "storeSetupCost":
        this.state.store.storeSetupCost = value;
        break;
      case "subscriptionDetails":
        this.state.store.subscriptionDetails = value;
        break;
      case "storeStart":
        this.state.store.storeStart = value;
        break;
      case "storeEnd":
        this.state.store.storeEnd = value;
        break;
      case "storeShortName":
        this.state.storeShortName = value;
        this.state.store.storeDomainName = value + ".elasticcart.com";
        this.state.store.storeAdminDomainName =
          value + "-admin.elasticcart.com";
        this.state.store.storePosDomainName = value + "-pos.elasticcart.com";
        this.state.store.invoiceFileShareName = value;
        this.state.store.storageBlobContainerName = value;
        break;
      case "storeDomainName":
        this.state.store.storeDomainName = value;
        break;
      case "storeAdminDomainName":
        this.state.store.storeAdminDomainName = value;
        break;
      case "storePosDomainName":
        this.state.store.storePosDomainName = value;
        break;
      case "storageBlobUrl":
        this.state.store.storageBlobUrl = value;
        break;
      // case "storageBlobContainerName":
      //   this.state.store.storageBlobContainerName = value;
      //   break;
      case "storageSasToken":
        this.state.store.storageSasToken = value;
        break;
      case "invoiceFileShareAccountUrl":
        this.state.store.invoiceFileShareAccountUrl = value;
        break;
      case "storageConnectionString":
        this.state.store.storageConnectionString = value;
        break;
      // case "invoiceFileShareName":
      //   this.state.store.invoiceFileShareName = value;
      //   break;
    }
    this.setState({ store: this.state.store });
  };

  StoreStartChange = (date) => {
    var tstore = this.state.store;
    tstore.storeStart = date;
    this.setState({ store: tstore });
  };
  StoreEndChange = (date) => {
    var tstore = this.state.store;
    tstore.storeEnd = date;
    this.setState({ store: tstore });
  };
  StoreShortNameChange = () => {};
  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ store: this.props.store }, () => {
        var tstore = this.state.store;
        tstore.storeStart = new Date(this.state.store.storeStart);
        tstore.storeEnd = new Date(this.state.store.storeEnd);
        this.setState({ storeShortName: tstore.storeDomainName.split(".")[0] });
        this.setState({ store: tstore });
      });
      if (this.props.store.enterpriseStores)
        this.setState({ enterpriseStores: this.props.store.enterpriseStores });
    } else {
      this.state.store.storeStatusId = 1;
      this.state.store.isTrail = true;
      this.state.store.storeStart = new Date();

      var result = new Date();
      result = result.setDate(result.getDate() + 15);
      this.state.store.storeEnd = new Date(result);

      this.state.store.storeDomainName = "";
      this.state.store.storeAdminDomainName = "";
      this.state.store.storePosDomainName = "";
      this.state.store.storageConnectionString =
        this.context.azureSetting.storageConnectionString;
      //"DefaultEndpointsProtocol=https;AccountName=ecdevstorage01;AccountKey=trKJkMbtuIPVNV7nP8kfB/p8pW5FTxXEUuWZ5jZlugMfs86aNIwSxKjvlhmiOiTpJvYjR/AiaSmM+AStnjvgKw==;EndpointSuffix=core.windows.net";

      this.state.store.storageBlobUrl =
        this.context.azureSetting.storageBlobUrl;
      //"https://ecdevstorage01.blob.core.windows.net/";
      this.state.store.storageBlobContainerName = "";
      this.state.store.storageSasToken =
        this.context.azureSetting.storageSasToken;
      //  "?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2099-11-02T22:00:52Z&st=2022-11-02T14:00:52Z&spr=https&sig=X4yTsS6CwyqTQMWTRwGKeOc5U7YdgJjBcIMhzKxyKv8%3D";
      this.state.store.invoiceFileShareAccountUrl =
        this.context.azureSetting.invoiceFileShareAccountUrl;
      //"https://ecdevstorage01.file.core.windows.net/";
      this.state.store.invoiceFileShareName = "";
      this.setState({ store: this.state.store });
    }
  }

  enableToAdminOnly = () => {
    if (this.context.UserRoles.OWNER == this.context.userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="StoreModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit Store" : "Add Store"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Tenant
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="tenantId"
                        value={this.state.store.tenantId}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value={0}></option>
                        {this.context.tenants.map((t) => (
                          <>
                            <option value={t.id}>
                              {t.firstName + " " + t.lastName}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store Business Type
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="businessTypeId"
                        value={this.state.store.businessTypeId}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value={0}></option>
                        {this.context.businessTypes.map((t) => (
                          <>
                            <option value={t.id}>{t.name}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store License Edition
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="storeTypeId"
                        value={this.state.store.storeTypeId}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value={0}></option>
                        {this.context.storeTypes.map((t) => (
                          <>
                            <option value={t.id}>{t.name}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="name"
                        value={this.state.store.name}
                        className="fw-bold"
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store Status
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="storeStatusId"
                        value={this.state.store.storeStatusId}
                        required
                        placeholder=""
                        autoComplete="off"
                        className="fw-bold"
                        size="lg"
                        disabled={this.enableToAdminOnly()}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option></option>
                        {this.context.storeStatusTypes.map((t) => (
                          <>
                            <option value={t.id}>{t.statusName}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Trail License?
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="isTrail"
                        value={this.state.store.isTrail}
                        required
                        placeholder=""
                        autoComplete="off"
                        className="fw-bold"
                        size="lg"
                        disabled={this.enableToAdminOnly()}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option></option>
                        <option value={true}>yes</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store Start
                    </Form.Label>
                    <Col sm="9">
                      <DatePicker
                        format="dd-MM-y"
                        onChange={(e) => this.StoreStartChange(e)}
                        value={this.state.store.storeStart}
                        disabled={this.enableToAdminOnly()}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store Expire on
                    </Form.Label>
                    <Col sm="9">
                      <b>
                        <DatePicker
                          format="dd-MM-y"
                          onChange={(e) => this.StoreEndChange(e)}
                          value={this.state.store.storeEnd}
                          disabled={this.enableToAdminOnly()}
                        />
                      </b>
                    </Col>
                  </Form.Group>
                  <div className="border p-3">
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="3">
                        Monthly Store Cost
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          name="monthlyCost"
                          value={this.state.store.monthlyCost}
                          className="fw-bold"
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="3">
                        Monthly Cost Discount (%)
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          name="monthlyCostDiscount"
                          value={this.state.store.monthlyCostDiscount}
                          className="fw-bold"
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="3">
                        Monthly Store Cost After Discount
                      </Form.Label>
                      <Col sm="9">
                        {this.state.store.monthlyCost -
                          (this.state.store.monthlyCost *
                            this.state.store.monthlyCostDiscount) /
                            100}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="3">
                        Transaction Charges %
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          name="transactionCharges"
                          value={this.state.store.transactionCharges}
                          className="fw-bold"
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="3">
                        Store Setup Cost
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          name="storeSetupCost"
                          value={this.state.store.storeSetupCost}
                          className="fw-bold"
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="3">
                        Subscription Details
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={3}
                          name="subscriptionDetails"
                          value={this.state.store.subscriptionDetails}
                          className="fw-bold"
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col md={6} className="">
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store Short Name [to use on Domain]:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storeShortName"
                        value={this.state.storeShortName}
                        required
                        readOnly={
                          this.enableToAdminOnly() && this.props.Type == "Edit"
                            ? true
                            : false
                        }
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Store Domain Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storeDomainName"
                        value={this.state.store.storeDomainName}
                        required
                        placeholder=""
                        autoComplete="off"
                        readOnly={this.enableToAdminOnly()}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Admin Domain Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storeAdminDomainName"
                        value={this.state.store.storeAdminDomainName}
                        required
                        placeholder=""
                        autoComplete="off"
                        readOnly={this.enableToAdminOnly()}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      POS Domain Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storePosDomainName"
                        value={this.state.store.storePosDomainName}
                        required
                        placeholder=""
                        autoComplete="off"
                        readOnly={this.enableToAdminOnly()}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-1 "
                    controlId=""
                    hidden={this.enableToAdminOnly()}
                  >
                    <Form.Label column sm="3">
                      Storage ConnectionString:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storageConnectionString"
                        value={this.state.store.storageConnectionString}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-1 "
                    controlId=""
                    hidden={this.enableToAdminOnly()}
                  >
                    <Form.Label column sm="3">
                      Storage Container SAS Token:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storageSasToken"
                        value={this.state.store.storageSasToken}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-1 "
                    controlId=""
                    hidden={this.enableToAdminOnly()}
                  >
                    <Form.Label column sm="3">
                      Storage Blob Container URL:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storageBlobUrl"
                        value={this.state.store.storageBlobUrl}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-1 "
                    controlId=""
                    hidden={this.enableToAdminOnly()}
                  >
                    <Form.Label column sm="3">
                      Image Storage Container Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="storageBlobContainerName"
                        value={this.state.store.storageBlobContainerName}
                        required
                        readOnly
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-1 "
                    controlId=""
                    hidden={this.enableToAdminOnly()}
                  >
                    <Form.Label column sm="3">
                      Invoice FileShare Account Url:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="invoiceFileShareAccountUrl"
                        value={this.state.store.invoiceFileShareAccountUrl}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-1 "
                    controlId=""
                    hidden={this.enableToAdminOnly()}
                  >
                    <Form.Label column sm="3">
                      Invoice FileShare Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="invoiceFileShareName"
                        readOnly
                        value={this.state.store.invoiceFileShareName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              {/* Add Enterprise Stores */}
              <div className="bg-light border  p-2 m-2">
                <Row className="m-0">
                  <Col md={8} className="text-start text-dark my-3">
                    <span>
                      <h4>Enterprise Stores</h4>
                      <h6></h6>
                    </span>
                  </Col>
                  <Col md={4} className="text-end align-bottom">
                    <Button
                      variant="primary"
                      classname="px-2"
                      onClick={(e) => this.handleAddEnterpriseStores(e)}
                    >
                      + Add
                    </Button>
                    &nbsp;&nbsp;
                  </Col>
                </Row>
                <Row className="m-0 p-0 bg-light border">
                  <Col>
                    <Table bordered size="sm" className=" text-start">
                      <thead className="bg-secondary text-white">
                        <tr size="lg">
                          <td width="40%" className="text-start">
                            Child StoreID
                          </td>
                          <td width="20%" className="text-center">
                            Location
                          </td>
                          <td width="30%" className="text-center">
                            Pincodes
                          </td>
                          <td width="10%" className="text-center">
                            Option
                          </td>
                        </tr>
                      </thead>
                      <tbody size="lg">
                        {this.state.enterpriseStores != undefined
                          ? this.state.enterpriseStores.map((row, i) => (
                              <>
                                <tr height="30pt" className="align-middle ">
                                  <td>
                                    <Form.Select
                                      type="text"
                                      name="childStoreId"
                                      value={row.childStoreId}
                                      required
                                      placeholder="select Main Category"
                                      autoComplete="off"
                                      onChange={(e) =>
                                        this.handleEnterpriseStoresSelectionChange(
                                          e,
                                          i
                                        )
                                      }
                                    >
                                      <option value={0}></option>
                                      {this.context.stores.map((s) => (
                                        <option value={s.id}>
                                          {s.name + "-" + s.id}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      name="location"
                                      value={row.location}
                                      className="fw-bold"
                                      required
                                      placeholder=""
                                      autoComplete="off"
                                      onChange={(e) =>
                                        this.handleEnterpriseStoresSelectionChange(
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      rows={1}
                                      name="pincodes"
                                      value={row.pincodes}
                                      className="fw-bold"
                                      required
                                      placeholder=""
                                      autoComplete="off"
                                      onChange={(e) =>
                                        this.handleEnterpriseStoresSelectionChange(
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </td>

                                  <td className="text-center">
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete!</Tooltip>}
                                    >
                                      <Button
                                        variant="outline-danger"
                                        onClick={(e) => {
                                          this.handleRemoveClick(i);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </>
                            ))
                          : ""}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
