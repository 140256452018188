import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Button, NavDropdown } from "react-bootstrap";

export class NavigationMobile extends Component {
  render() {
    return (
      <Navbar bg="primary" variant="dark" expand="lg">
        <Navbar.Brand href="https://rany.tk">
          <img src="./images/logo.svg" width="40" height="40" />
          Pinapple Express
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <NavLink className="d-inline p-2 bg-dark text-white" to="/">
              Home
            </NavLink>
            <NavLink className="d-inline p-2 bg-dark text-white" to="/Products">
              Products
            </NavLink>
            <NavLink
              className="d-inline p-2 bg-dark text-white"
              to="/ProductsList"
            >
              ProductsList
            </NavLink>
            <NavDropdown
              title={<span className="text-light my-auto">Store Owner</span>}
              id="navbarScrollingDropdown"
              className="text-white"
            >
              <NavDropdown.Item href="#action3">Dashboard</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Login</NavDropdown.Item>
              <NavDropdown.Item href="#action4">LogOut</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Forgot Password
              </NavDropdown.Item>
              <NavDropdown.Item href="#action4">Edit Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">Manage Store</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={<span className="text-light my-auto">Store Manager</span>}
              id="navbarScrollingDropdown"
              className="text-white"
            >
              <NavDropdown.Item href="#action3">Dashboard</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Login</NavDropdown.Item>
              <NavDropdown.Item href="#action4">LogOut</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Forgot Password
              </NavDropdown.Item>
              <NavDropdown.Item href="#action4">Edit Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Product Categories
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">Products</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={<span className="text-light my-auto">Consumer</span>}
              id="navbarScrollingDropdown"
              className="text-white"
            >
              <NavDropdown.Item href="#action3">Dashboard</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Login</NavDropdown.Item>
              <NavDropdown.Item href="#action4">LogOut</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Forgot Password
              </NavDropdown.Item>
              <NavDropdown.Item href="#action4">Edit Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">Products List</NavDropdown.Item>
              <NavDropdown.Item href="#action5">My Cart</NavDropdown.Item>
              <NavDropdown.Item href="#action5">My Orders</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
