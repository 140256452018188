import React, { Component } from "react";
import { Link } from "react-router-dom";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";

import {
  Navbar,
  Nav,
  Badge,
  OverlayTrigger,
  Popover,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";

export default class Navigation extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      loadingFirstTime: true,
    };
  }
  handleOnMouseEnter = () => {
    this.setState({ showOverlay: true });
  };
  handleOnMouseLeave = () => {
    this.setState({ showOverlay: false });
  };
  componentDidMount() {
    if (this.context.tenants.length == 0 && this.context.stores.length == 0) {
      this.context.GetBusinessTypes();
      this.context.GetStoreTypes();
      this.context.GetStoreStatusTypes();
      this.context.GetTenants();
      this.context.GetStores();
    }
  }
  componentDidUpdate() {}

  render() {
    if (this.state.loadingFirstTime == true) {
      this.setState({ loadingFirstTime: false });
      return <Navigate to="/UserLogin" />;
    }
    return (
      <>
        <div className="Container-fluid mx-0 px-0 bg-dark ">
          <Navbar className="mainnavbar mainthemecolor position-sticky fixed-top">
            {/* logo and title */}
            <Navbar.Collapse className="justify-content-start mx-0">
              <Nav>
                <Nav.Link as={Link} to="/home" className=" ">
                  <span>
                    <h3 className="adminstorename ">
                      <span className="text-white">ElasticCart</span>{" "}
                      <b className=""> - Sales & Support </b> -v1.1
                      {/* <img
                        src={
                          this.context.commonSettings.storgeContainerPath +
                          this.context.commonSettings.storeLogoFileName +
                          this.context.commonSettings.storgeContainerSasToken
                        }
                        width="40"
                        height="40"
                      />
                      &nbsp;
                      {this.context.commonSettings.storeName} */}
                    </h3>
                  </span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>

            {/* <MainMenu></MainMenu> */}

            <Navbar.Collapse className="justify-content-end mx-2 text-center">
              <Nav className="navBarLink m-0 p-0 text-center">
                {/* Signup/Login */}
                <div className="">
                  <Nav.Link
                    as={Link}
                    className="  navBarLink "
                    to="/UserLogin"
                    hidden={this.context.userData.hideSignupLink}
                    onClick={(e) => this.handleOnMouseLeave()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    <small>&nbsp; Login</small>
                  </Nav.Link>
                </div>

                {/* User */}

                {this.context.userData.hideSignupLink == "true" ? (
                  <>
                    <OverlayTrigger
                      show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                      key="bottom"
                      placement="bottom"
                      rootClose="false"
                      overlay={
                        <Popover
                          id={`popover-positioned-bottom`}
                          onMouseEnter={this.handleOnMouseEnter}
                          onMouseLeave={this.handleOnMouseLeave}
                          className="bg-dark"
                        >
                          <Popover.Header as="h3"></Popover.Header>
                          <Popover.Body>
                            <UserMenu
                              handleOnMouseLeave={this.handleOnMouseLeave}
                            ></UserMenu>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button
                        style={{ height: "30px", width: "100px" }}
                        // variant="dark"
                        className="mainthemecolor border-0"
                        hidden={!this.context.userData.hideSignupLink}
                        onMouseEnter={this.handleOnMouseEnter}
                        onMouseLeave={this.handleOnMouseLeave}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            class="bi bi-person-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path
                              fill-rule="evenodd"
                              d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                            />
                          </svg>
                          &nbsp;Hi&nbsp;
                          {this.context.userData.emailId}
                        </span>
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  ""
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    );
  }
}

class UserMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ListGroup variant="flush" className="fw-bold bg-dark">
          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/UserChangePassword"
          >
            Change Password
          </ListGroup.Item>

          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/home"
            onClick={(e) => {
              this.props.handleOnMouseLeave();
              this.context.clearUserData();
            }}
          >
            Sign Out
          </ListGroup.Item>
        </ListGroup>
      </>
    );
  }
}
