import React, { Component, useState } from "react";
import { SiHackthebox } from "react-icons/si";
import { RiFeedbackLine } from "react-icons/ri";
import { BsCartX } from "react-icons/bs";
import { Accordion, Nav, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";

export class SideMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productDefaultActiveKey: "1",
      customerDefaultActiveKey: "1",
      marketingDefaultActiveKey: "1",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className="bg-white overflow-scroll "
        style={{ overflowY: "scroll", height: "calc(510vh - 100px)" }}
      >
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Home"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Dashboard
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Tenants"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Tenants
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Stores"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Stores
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Home"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Renewal
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Home"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Invoice
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Home"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Users
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Home"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Customers
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="px-0 py-2 mx-0 my-0 ">
          <Nav.Link
            as={Link}
            style={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
            className="w-100 py-2 mx-0 my-2 mb-0 bg-white border-0 text-dark text-start menuNav.Link"
            to="/Home"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
            &nbsp;&nbsp;Settings
          </Nav.Link>
        </ListGroup.Item>
      </div>
    );
  }
}
