import React, { Component, useState } from "react";
import { SideMenu } from "./SideMenu";
import { SiHackthebox } from "react-icons/si";
import { RiFeedbackLine } from "react-icons/ri";
import { BsCartX } from "react-icons/bs";
import { LoadSpinner } from "./LoadSpinner";
import { Navigate } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Accordion,
  Button,
  Carousel,
  Nav,
  ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";

export class Home extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };

  componentDidMount() {
    // this.fakeRequest().then(() => {
    //   this.setState({ loadSpinnerOpen: false }); // showing the app
    // });
  }

  render() {
    if (this.context.userData.loginStatus === "inactive") {
      return <Navigate to="/UserLogin" />;
    }
    return (
      <div className="Container-fluid mx-0 px-0">
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <Row>
          <Col className="bg-light " xs={2}>
            <SideMenu></SideMenu>
          </Col>
          <Col xs={10} className="bg-light">
            <h1>Hi Dashboard</h1>
          </Col>
        </Row>
      </div>
    );
  }
}
