import React, { Component } from "react";
import { useState } from "react";
import { SideMenu } from "./SideMenu";
import CartContext from "./CartContext";
import { BsFillPencilFill } from "react-icons/bs";
import Fuse from "fuse.js";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  Nav,
  Stack,
  InputGroup,
  FormControl,
  Table,
  OverlayTrigger,
  Tooltip,
  Navbar,
  ListGroup,
} from "react-bootstrap";
import { Button, ButtonToolbar, Form, Row, Col } from "react-bootstrap";

import { TenantAddOrEditModal } from "./TenantAddOrEditModal";

export class Tenants extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      addModalShow: false,
      editModalShow: false,
      // selectedMainCategoryId: "0",
      // selectedMainCategoryName: "All",
      // selectedSubCategoryId: "0",
      // selectedSubCategoryName: "All",
      filteredTenants: [],
      filteredTenantsCopy: [],
      searchData: [],
      searchquery: "",
      reload: true,
      loading: true,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  // onMainCategorySelectChangeEvent = (e) => {
  //   this.setState({ reload: false });
  //   this.setState({ selectedMainCategoryId: e.target.value }, () => {
  //     this.setState({ selectedSubCategoryId: "0" }, () =>
  //       this.OnSubCategoryIDChanges()
  //     );
  //   });
  // };
  // OnSubCategoryIDChanges = () => {
  //   this.onFilterChanges();
  // };
  // onSubCategorySelectChangeEvent = (e) => {
  //   this.setState({ reload: false });
  //   this.setState({ selectedSubCategoryId: e.target.value }, () =>
  //     this.onFilterChanges()
  //   );
  // };
  onFilteredTenantsChanged = () => {
    this.setState({ filteredTenantsCopy: this.state.filteredTenants }, () => {
      this.setState({ reload: true }, () => {
        this.HideLoadSpinner();
      });
      console.log("Tenants reloaded");
    });
  };

  OnDeleted = (indexno) => {
    this.setState({ reload: false });
    const list = [...this.state.filteredTenants];
    list.splice(indexno, 1);
    this.setState({ filteredTenants: list }, () => {
      this.setState({ reload: true }, () => {
        this.ShowMessagBox("Information", "Deleted Successfully!");
      });
    });
  };

  OnUpdated = (indexno) => {
    this.setState({ reload: true }, () => this.setState({ reload: true }));
  };

  OnRefreshButtonClick = () => {
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  };
  useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () =>
      setValue(
        (value) => value + 1,
        () => {}
      ); // update the state to force render
  };
  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };
  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      //this.setState({ searchData: [] });
      this.setState({ filteredTenants: this.state.filteredTenantsCopy }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.filteredTenants, {
      keys: ["firstName", "lastName", "contactNo", "city"],
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredTenants: finalResult }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredTenants: [] });
    }
  };

  onFilterChanges = () => {
    this.setState(
      {
        filteredTenants: [...this.context.tenants], //deep clone
      },
      () => this.onFilteredTenantsChanged()
    );
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  };

  componentDidMount() {
    this.fakeRequest().then(() => {
      this.setState({ loading: false }); // showing the app
    });
    this.onFilterChanges();
  }

  componentDidUpdate() {}

  render() {
    if (this.state.loading === true) {
      return (
        <>
          <LoadSpinner open="true"></LoadSpinner>
        </>
      );
    }

    const {} = this.state;
    return (
      <div className="Container-fluid p-3 pagebackground">
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <Row>
          <Col className="" xs={2}>
            <SideMenu></SideMenu>
          </Col>
          <Col xs={10} className="pagebackground">
            <Navbar className="secondarythemecolor position-sticky fixed-top  my-0">
              <h4> &nbsp; &nbsp;TENANTs MANAGEMENT &nbsp;</h4>
              <Navbar.Collapse className="justify-content-end mx-1 ">
                <Nav>
                  <ButtonToolbar className="text-end align-middle">
                    <Button
                      className="mainthemecolor"
                      onClick={() => this.setState({ addModalShow: true })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-plus"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                      Add
                    </Button>
                    &nbsp;
                    <Button
                      className="mainthemecolor"
                      onClick={() => this.OnRefreshButtonClick()}
                    >
                      <BiRefresh
                        size="26"
                        className="mainthemecolor"
                      ></BiRefresh>
                      Refresh
                    </Button>
                    &nbsp;
                    {/* this logical check is required to deal with object destraction after close or submit the model */}
                    {this.state.addModalShow === true ? (
                      <TenantAddOrEditModal
                        show={this.state.addModalShow}
                        Type="Add"
                        onHide={this.addModalClose}
                      />
                    ) : null}
                  </ButtonToolbar>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Row>
              <Col className=" py-1" xs={2}>
                <div className=" ">
                  <div className="my-2 p-1">
                    <Table className=" my-0 py-0">
                      <Row>
                        <Col className="text-secondary bg-white">
                          <div className="py-2  text-center text-dark">
                            <h4>Filters</h4>
                          </div>
                          <br></br>

                          {/* <div className="px-0 py-4 mx-0 my-0 border"> */}
                          <h6 className="py-2">
                            <b>Country</b>
                          </h6>
                          <Form.Select
                            size=""
                            // value={this.state.selectedMainCategoryId}
                            onChange={(e) => {
                              // this.onMainCategorySelectChangeEvent(e);
                            }}
                          >
                            <option value={0}>ALL</option>
                            {/* {this.context.mainCategories.map((maincategory) => (
                              <option value={maincategory.id}>
                                {maincategory.name}
                              </option>
                            ))} */}
                          </Form.Select>
                          <br></br>
                          <h6 className="py-2">
                            <b>State</b>
                          </h6>
                          <Form.Select
                            // value={this.state.selectedSubCategoryId}
                            onChange={(e) => {
                              // this.onSubCategorySelectChangeEvent(e);
                            }}
                          >
                            <option value={0}>All</option>
                            {/* {this.context.subCategories
                              .filter(
                                (subcategory) =>
                                  Number(subcategory.mainCategoryId) ===
                                  Number(this.state.selectedMainCategoryId)
                              )
                              .map((subcategory) => (
                                <option value={subcategory.id}>
                                  {subcategory.name}
                                </option>
                              ))} */}
                          </Form.Select>
                          <br></br>
                          <h6 className="py-2">
                            <b>City</b>
                          </h6>
                          <Form.Select
                            // value={this.state.selectedSubCategoryId}
                            onChange={(e) => {
                              // this.onSubCategorySelectChangeEvent(e);
                            }}
                          >
                            <option value={0}>All</option>
                            {/* {this.context.subCategories
                              .filter(
                                (subcategory) =>
                                  Number(subcategory.mainCategoryId) ===
                                  Number(this.state.selectedMainCategoryId)
                              )
                              .map((subcategory) => (
                                <option value={subcategory.id}>
                                  {subcategory.name}
                                </option>
                              ))} */}
                          </Form.Select>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </Col>
                      </Row>
                    </Table>
                  </div>
                </div>
              </Col>
              <Col xs={10} className=" py-1">
                <div className=" ">
                  <div className=" my-2 p-1">
                    <Table className="my-0 py-0">
                      <Row className="">
                        <Col>
                          <Stack direction="horizontal" gap={3} className="">
                            <b>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-search"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </b>
                            <FormControl
                              type="search"
                              placeholder="Search by Name/ContactNumber/City... "
                              className="text-dark fw-bold bg-white "
                              size="lg"
                              // disabled="true"
                              aria-label="Search"
                              onChange={(e) =>
                                this.onSearchChange(e.target.value)
                              }
                            />
                          </Stack>
                        </Col>
                      </Row>
                    </Table>
                  </div>
                  <Table border striped className="px-0 bg-white ">
                    <thead className="">
                      <tr className="text-center text-black bg-white border">
                        <th width="5%">S.No</th>
                        <th width="10%">Tenant-Id</th>
                        <th width="10%">Created</th>
                        <th width="10%">Name</th>
                        <th width="10%">ContactNo</th>
                        {/* <th width="10%">email-id</th> */}
                        <th width="10%">BusinessName</th>
                        <th width="10%">Biz.ContactNo</th>
                        {/* <th width="10%">B.ContactNo2</th> */}
                        {/* <th width="10%">BusinessEmailId</th> */}
                        <th width="10%">Location</th>
                        <th width="10%" className="text-center">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-primary border" fontSize="20px">
                      {this.state.filteredTenants
                        .sort((a, b) => (a.created > b.created ? 1 : -1))
                        .map((tenant, i) =>
                          this.state.reload === true ? (
                            <EditTenantInline
                              index={i}
                              tenant={tenant}
                              OnDeleted={this.OnDeleted}
                              OnUpdated={this.OnUpdated}
                            ></EditTenantInline>
                          ) : null
                        )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export class EditTenantInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      tenant: this.props.tenant,
      editModalShow: false,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      // case "hideMe":
      //   this.state.tenant.hideMe = value;
      //   break;
      // case "mrp":
      //   this.state.tenant.mrp = value;
      //   break;
      // case "discount":
      //   this.state.tenant.discount = value;
      //   break;
      // case "inStockQty":
      //   this.state.tenant.inStockQty = value;
      //   break;
      default:
        break;
    }
    // this.setState({ tenant: this.state.prod });
  };

  OnDelete = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API + "Sales/DeleteTenant/" + this.state.tenant.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Delete Failed! try after sometime");
          return;
        }

        this.context.GetTenants();
        this.HideLoadSpinner();
        this.props.OnDeleted(this.props.index);
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Delete Failed! try after sometime");
      });
  };

  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      this.props.OnUpdated();
    });
  };
  componentDidMount() {}
  enableDeleteButtonMenu = () => {
    if (this.context.UserRoles.OWNER == this.context.userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <TenantAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            tenant={this.state.tenant}
            Type="Edit"
            // mainCategoryId={this.getMainCategoryId(
            //   this.state.prod.subCategoryId
            // )}
          />
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "11px" }}
        >
          <td className="text-center ">{this.props.index + 1}</td>
          <td className="text-center ">{this.state.tenant.id}</td>
          <td className="text-center ">
            {new Date(this.state.tenant.created).toLocaleString()}
          </td>
          <td className="text-center ">
            {this.state.tenant.firstName} {this.state.tenant.lastName}
          </td>
          <td className="text-center ">{this.state.tenant.contactNo}</td>
          {/* <td className="text-center ">{this.state.tenant.emailId}</td> */}
          <td className="text-center ">{this.state.tenant.businessName}</td>
          <td className="text-center ">
            {this.state.tenant.businessContactNo1}
          </td>
          {/* <td className="text-center ">{this.state.tenant.businessEmailId}</td> */}
          <td className="text-center ">
            {this.state.tenant.city}, {this.state.tenant.state},{" "}
            {this.state.tenant.country}
          </td>

          <td className="text-cener">
            <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
              <Button
                className="mainthemecolor"
                onClick={(e) => {
                  this.OnEditButtonClick(e);
                }}
              >
                <BsFillPencilFill size="16" />
              </Button>
            </OverlayTrigger>
            &nbsp;&nbsp;&nbsp;
            <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
              <Button
                className="mainthemecolor"
                disabled={this.enableDeleteButtonMenu()}
                onClick={(e) => {
                  if (window.confirm("Are you sure you want to Delete ?")) {
                    this.OnDelete(e);
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      </>
    );
  }
}
