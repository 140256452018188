import React, { createContext, useState, useEffect } from "react";
import Fuse from "fuse.js";
import axios from "axios";

const CartContext = createContext();
export default CartContext;

export function CartProvider({ children }) {
  const UserRoles = {
    OWNER: 1,
    CONTRIBUTOR: 2,
    SUPPORT: 3,
  };
  const [commonSettings, setCommonSettings] = useState([]);
  const [myCart, setMyCart] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [OrderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [storeId, setSubscriptionId] = useState("1");
  const [guestUserTypeId, setGuestUserTypeId] = useState("2");
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const [azureSetting, setAzureSetting] = useState({});
  const [productUnitTypes, setProductUnitTypes] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [productGroupsAndProducts, setProductGroupsAndProducts] = useState([]);
  const [prods, setProducts] = useState([]);
  const [prodsAll, setProductsAll] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [mainCarousel, setMainCarousel] = useState([]);
  const [mainMenus, setMainMenus] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [stores, setStores] = useState([]);
  const [orderStatusTypes, setOrderStatusTypes] = useState([]);
  const [storeTypes, setStoreTypes] = useState([]);
  const [storeStatusTypes, setStoreStatusTypes] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    emailid: "",
    bearertoken: "",
    loginStatus: "inactive", //active/inactive
    hideSignupLink: "", //set "true" for hide; set "" for show
  });

  const storeOrderInformation = (data) => {
    setOrderData(data);
  };
  const UpdateOrderStatus = (status) => {
    setOrderStatus(status);
  };

  const GetAzureSetting = (data) => {
    const config = {
      headers: { Authorization: `Bearer ${data.bearerToken}` },
    };
    fetch(process.env.REACT_APP_API + "Sales/GetAzureSettings/", config)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setAzureSetting(data);
      })
      .catch((error) => {
        alert("GetAzureSetting Error:: " + error);
      });
  };

  const GetStoreTypes = () => {
    fetch(process.env.REACT_APP_API + "Common/GetStoreTypes/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setStoreTypes(data);
      })
      .catch((error) => {
        alert("GetStoreTypes Error:: " + error);
      });
  };
  const GetBusinessTypes = () => {
    fetch(process.env.REACT_APP_API + "Common/GetBusinessTypes/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setBusinessTypes(data);
      })
      .catch((error) => {
        alert("GetBusinessTypes Error:: " + error);
      });
  };

  const GetStoreStatusTypes = () => {
    fetch(process.env.REACT_APP_API + "Common/GetStoreStatusTypes/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setStoreStatusTypes(data);
      })
      .catch((error) => {
        alert("GetStoreTypes Error:: " + error);
      });
  };

  const GetTenants = () => {
    fetch(process.env.REACT_APP_API + "Sales/GetTenants/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setTenants(data);
      })
      .catch((error) => {
        alert("GetTenants Error:: " + error);
      });
  };
  const GetStores = () => {
    fetch(process.env.REACT_APP_API + "Sales/GetStores/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setStores(data);
      })
      .catch((error) => {
        alert("GetStores Error:: " + error);
      });
  };

  const GetProductGroups = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetProductGroups/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setProductGroups(data);
      })
      .catch((error) => {
        alert("GetProductGroups:: " + error);
      });
  };

  const GetProductGroupsAndProducts = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetProductGroupsDetail/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setProductGroupsAndProducts(data);
      })
      .catch((error) => {
        alert("GetProductGroupsAndProducts:: " + error);
      });
  };

  const GetOrderStatusTypes = () => {
    var res = axios
      .get(process.env.REACT_APP_API + "StoreManager/GetOrderStatusTypes/")
      .then((response) => {
        if (response.status === 200) {
          setOrderStatusTypes(response.data);
        }
      })
      .catch((error) => {
        console.error("GetOrderStatusTypes - There was an error! ", error);
      });
  };

  const GetMainMenus = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMainMenus/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setMainMenus(data);
      })
      .catch((error) => {
        alert("GetMainMenus:: " + error);
      });
  };

  const GetMainCarousel = (targetDevice) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetCarousel/" +
          storeId +
          "," +
          targetDevice
      )
      .then((response) => {
        if (response.status === 200) {
          setMainCarousel(response.data);
        }
      })
      .catch((error) => {
        console.error("GetCarousel - There was an error! ", error);
        alert("Error GetCarousel:" + error);
      });
  };

  const searchOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["title", "author.firstName"],
  };

  const getImageList = () => {
    return [
      { fileName: "" },
      { fileName: "amla.png" },
      { fileName: "Banganapalli.png" },
      { fileName: "chikku.jpg" },
      { fileName: "GuavaAlahabad.png" },
      { fileName: "GuavaTaiwan.png" },
      { fileName: "Malika.png" },
      { fileName: "GuavaTaiwan.png" },
      { fileName: "Malika.png" },
      { fileName: "Banganapalli.png" },
      { fileName: "chikku.jpg" },
      { fileName: "GuavaAlahabad.png" },
      { fileName: "GuavaTaiwan.png" },
      { fileName: "Malika.png" },
      { fileName: "Karpuravallibanana.jpg" },
      { fileName: "redbanana.png" },
      { fileName: "redlady.jpg" },
      { fileName: "BananaFlower.png" },
      { fileName: "BigOnion.png" },
      { fileName: "bitter.jpg" },
      { fileName: "beet1.png" },
      { fileName: "Broccoli.png" },
      { fileName: "cucumber.png" },
      { fileName: "GarlicNati.png" },
      { fileName: "Maravalli.png" },
      { fileName: "GreenChilli.png" },
      { fileName: "okra.jpg" },
      { fileName: "AmulCheese.jpg" },
      { fileName: "AmulGoldMilk.jpg" },
      { fileName: "AmulkMilk.jpg" },
      { fileName: "MilkyMistCurdContainer.jpg" },
      { fileName: "NandhiniMilk.jpg" },
      { fileName: "MilkyMistCheese.jpg" },
      { fileName: "IdlyPlates.jpg" },
      { fileName: "KichenRack.jpg" },
      { fileName: "SpoonContainerSteel.jpg" },
      { fileName: "SpoonContainerWood.jpg" },
      { fileName: "SteelTool.jpg" },
      { fileName: "VegCutter.jpg" },
      { fileName: "WoodenTools.jpg" },
      { fileName: "SnackCheetos.jpg" },
      { fileName: "SnackKodubale.jpg" },
      { fileName: "SnackMarieGold.jpg" },
      { fileName: "SnackMoongDal.jpg" },
      { fileName: "SnackRagiStick.jpg" },
      { fileName: "SnacPuffcorn.jpg" },
      { fileName: "AmulChocolate.jpg" },
      { fileName: "AmulCone.jpg" },
      { fileName: "AmulFruitNuts.jpg" },
      { fileName: "KuwalityMango.jpg" },
      { fileName: "ShahiAnjir.jpg" },
      { fileName: "Ashirwad.jpg" },
      { fileName: "Idayam.jpg" },
      { fileName: "Parle.jpg" },
      { fileName: "Amul.jpg" },
      { fileName: "Milky-Mist.jpg" },
    ];
  };

  const GetSearchResult = (pattenText) => {
    //setSearchData(options);
    const fuse = new Fuse(prods, searchOptions);
    setSearchData(fuse.search(pattenText));
  };

  const storePreviousPage = (value) => {
    setPrevPage(value);
  };

  const ClearCartAndOrderDetails = () => {
    setMyCart([{}]);
    localStorage.removeItem("localstoragecartitems");
    //setOrderData("");
  };

  const GetSubscriptionSetting = () => {
    var var1 =
      process.env.REACT_APP_API + "Consumers/GetSubscriptionSetting/" + storeId;
    fetch(
      process.env.REACT_APP_API + "Consumers/GetSubscriptionSetting/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setCommonSettings(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("GetSubscriptionSetting fetch err:" + error);
      });
  };

  const storeUserData = (data) => {
    setUserData({
      id: data.id,
      emailId: data.emailId,
      password: data.password,
      roleId: data.roleId,
      lastSignIn: data.lastSignIn,
      bearertoken: data.bearerToken,
      loginStatus: "active",
      hideSignupLink: "true", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    // setUserData((state) => {
    //   //todo: enable
    //   localStorage.setItem("localstorageUserData", JSON.stringify(state));
    //   return state;
    // });
  };
  const clearUserData = () => {
    setUserData({
      firstname: "",
      lastname: "",
      emailid: "",
      password: "",
      bearertoken: "",
      loginStatus: "inactive",
      hideSignupLink: "", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    setUserData((state) => {
      //todo: enable
      localStorage.setItem("localstorageUserData", JSON.stringify(state));
      return state;
    });
  };

  const RefreshBearerToken = (emailid, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: 0,
        firstName: "string",
        lastName: "string",
        mobileNo: "string",
        emailId: emailid,
        password: password,
        photoUrl: "string",
        address: "string",
        landmark: "string",
        city: "string",
        state: "string",
        country: "string",
        pinCode: "string",
        createdDateTime: "2022-06-13T12:34:21.806Z",
        modifiedDateTime: "2022-06-13T12:34:21.806Z",
      }),
    };

    fetch(process.env.REACT_APP_API + "Consumers/Login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          return;
        }
        storeUserData(data);
        console.log("storeUserData(data) success");
      })
      .catch((error) => {
        console.error("RefreshBearerToken: There was an error!", error);
        //alert("RefreshBearerToken err:" + error);
      });
  };
  const getMainCategories = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMainCategory/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setMainCategories(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("getMainCategories err:" + error);
      });
  };

  const getMainCategoryName = (mainCategoryId) => {
    var name = "";
    mainCategories.map((main) => {
      if (main.id == mainCategoryId) {
        console.log(main.name);
        name = main.name;
        return name;
      }
    });
    return name;
  };

  const getSubCategories = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetSubCategory/" + +storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setSubCategories(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("getSubCategories err:" + error);
      });
  };

  const getBrands = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetBrands/" + +storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setBrands(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const GetProductUnitTypes = () => {
    fetch(process.env.REACT_APP_API + "Common/GetProductUnitTypes")
      .then((response) => response.json())
      .then((data) => {
        setProductUnitTypes(data);
      })
      .catch((error) => {
        alert("GetProductUnitTypes Err:" + error);
        console.error("There was an error!", error);
      });
  };
  const GetAllProducts = () => {
    var querystring = `?MainCategoryId=-1&SubCategoryId=-1&filtertext=-1`;
    fetch(process.env.REACT_APP_API + "StoreManager/GetProducts/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setProducts(data);
          setProductsAll(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const DeleteProductFromMemory = (productId) => {
    setProducts(prods.filter((productitem) => productId !== productitem.id));
    setProductsAll(
      prodsAll.filter((productitem) => productId !== productitem.id)
    );
  };

  const UpdateProductInContext = (product) => {
    for (var i = 0; i < prods.length; i++) {
      if (prods[i].id == product.id) {
        prods[i] = product;
        break;
      }
    }

    for (var i = 0; i < prodsAll.length; i++) {
      if (prodsAll[i].id == product.id) {
        prodsAll[i] = product;
        break;
      }
    }
  };

  const GetProductsByMainCategoryId = (MainCategoryId) => {
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter(
        (productitem) =>
          Number(MainCategoryId) ==
          Number(productitem.subCategory.mainCategoryId)
      )
    );
  };
  const GetProductsBySubCategoryId = (CategoryId) => {
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter(
        (productitem) => Number(CategoryId) == Number(productitem.subCategoryId)
      )
    );
  };

  //create your forceUpdate hook
  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
  }
  const forceUpdateCartView = useForceUpdate();

  const addToMyCart = (cartitem) => {
    //check if product items exist, go for update
    const indexItem = myCart.findIndex((item) => item.id == cartitem.id);
    if (indexItem != -1) {
      //update
      myCart.map((item) => {
        if (item.id == cartitem.id) {
          item.qty = Number(item.qty) + Number(cartitem.qty);
        }
      });
    } else {
      // add
      setMyCart([...myCart, cartitem]);
    }

    //this is a callback. store the cart in local store
    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const deleteItemOnMyCart = (id) => {
    setMyCart(myCart.filter((productitem) => id !== productitem.id));

    //this is a callback. store the cart in local store
    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const ClearCookies = () => {
    //ClearCookies
    localStorage.setItem("localstoragecartitems", "");
    localStorage.setItem("localstorageUserData", "");
  };

  //when page is reloaded, restore the cart from cookie
  //todo: clear the cookie when localstorage is older than a week/month
  useEffect(() => {
    // let v1 = localStorage.getItem("localstoragecartitems");
    // if (v1 !== null && v1 !== `""` && v1 !== ``) setMyCart(JSON.parse(v1));
    // v1 = localStorage.getItem("localstorageUserData");
    // if (v1 !== null && v1 !== `""` && v1 !== ``) setUserData(JSON.parse(v1));
  }, []);

  const ClearAllCookies = () => {
    //1. clear Cart Items
    localStorage.setItem("localstoragecartitems", null);
  };

  const getTotalQtyInCart = (myCart) => {
    var totqty = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totqty = totqty + Number(item.qty);
      });
    }
    return totqty;
  };
  const getTotalPriceInCart = (myCart) => {
    var totprice = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totprice = totprice + Number(item.qty) * Number(item.price);
      });
    }
    return totprice;
  };

  const updateMyCart = (cartitem) => {};

  return (
    <CartContext.Provider
      value={{
        storeId,
        azureSetting,
        tenants,
        stores,
        commonSettings,
        guestUserTypeId,
        searchData,
        myCart,
        mainCategories,
        subCategories,
        brands,
        productUnitTypes,
        prods,
        productGroups,
        productGroupsAndProducts,
        userData,
        paymentStatus,
        prevPage,
        orderData,
        OrderStatus,
        mainMenus,
        mainCarousel,
        orderStatusTypes,
        storeTypes,
        storeStatusTypes,
        UserRoles,
        businessTypes,
        GetStoreTypes,
        GetStoreStatusTypes,
        RefreshBearerToken,
        storeUserData,
        clearUserData,
        addToMyCart,
        deleteItemOnMyCart,
        forceUpdateCartView,
        GetTenants,
        GetStores,
        GetSubscriptionSetting,
        getTotalQtyInCart,
        getTotalPriceInCart,
        getMainCategories,
        getSubCategories,
        getBrands,
        GetAllProducts,
        GetProductsByMainCategoryId,
        GetProductsBySubCategoryId,
        GetSearchResult,
        GetProductUnitTypes,
        ClearCartAndOrderDetails,
        storePreviousPage,
        UpdateOrderStatus,
        storeOrderInformation,
        getMainCategoryName,
        getImageList,
        GetMainMenus,
        GetMainCarousel,
        GetOrderStatusTypes,
        GetProductGroups,
        GetProductGroupsAndProducts,
        setSubscriptionId,
        DeleteProductFromMemory,
        UpdateProductInContext,
        GetBusinessTypes,
        GetAzureSetting,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
