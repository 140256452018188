import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Audio } from "react-loader-spinner";

export class LoadSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      callback: this.props.callback,
    };
  }
  customstyle = {
    content: {
      ...Modal,
    },
    overlay: { zIndex: 1000 },
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.callback();
  };
  render() {
    return (
      <>
        <Modal
          size="sm"
          style={this.customStyles}
          show={this.state.open}
          backdrop="static"
          keyboard={false}
          // onHide={this.handleClose}
        >
          <Modal.Body>
            {" "}
            <Audio height="100" width="300" color="grey" ariaLabel="loading" />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
