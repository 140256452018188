import React, { Component } from "react";

import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { Container, Row, Col, Nav, Form, Button, Alert } from "react-bootstrap";

export default class UserLogin extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-1 ">
          <div className="row mt-2 mx-1 px-0">
            <div className="col-md-4 "></div>
            <div className="col-md-4 ">
              <br />
              <br />
              <h2 className="text-center">
                <b>Login Form</b>{" "}
              </h2>
              <Login></Login>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class Login extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      password: "",
      postId: "",
      isValidLogin: "false",
      loginResultText: "",
      userData: null,
    };

    this.handleChangeemailId = this.handleChangeemailId.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeemailId(event) {
    this.setState({ emailId: event.target.value });
    this.setState({ loginResultText: "" });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
    this.setState({ loginResultText: "" });
  }

  ValidateLogin(emailId, password) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        emailId: this.state.emailId,
        password: this.state.password,
      }),
    };

    fetch(process.env.REACT_APP_API + "Sales/Login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404 || data.status == 400) {
          this.setState({ isValidLogin: false });
          this.setState({ loginResultText: "Invalid Username/Password" });
          return;
        }
        this.setState({ isValidLogin: true });
        this.setState({ loginResultText: "Login success!" });
        this.setState({ userData: data });
        this.context.storeUserData(data);
        this.context.GetAzureSetting(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("err:" + error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.ValidateLogin(this.state.emailId, this.state.password);
  }
  componentDidMount() {}
  componentDidUpdate() {}

  render() {
    if (this.state.isValidLogin == true) {
      return <Navigate to="/Home" />;
    }
    return (
      <>
        <br></br>
        <div className="mx-0 px-3 p-3 bg-secondary border">
          <Form onSubmit={this.handleSubmit}>
            {/* Enter Email */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="text-white">
                <h5>UserID</h5>
              </Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter your UserID"
                value={this.state.emailId}
                onChange={this.handleChangeemailId}
              />
            </Form.Group>

            {/* Enter Password */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="text-white">
                <h5>Password</h5>
              </Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="Enter Password"
                value={this.state.password}
                onChange={this.handleChangePassword}
              />
            </Form.Group>

            {/* Login Submit */}
            <div className="text-center text-warning">
              <h5 className="blink_me">{this.state.loginResultText}</h5>
            </div>
            <br></br>
            <div className="text-center">
              <Button
                type="submit"
                variant="primary"
                className="w-25 "
                size="lg"
              >
                <b>Login</b>
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
