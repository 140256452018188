import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Container } from "react-bootstrap";
import React, { createContext, useEffect, useState } from "react";
import Navigation from "./Components/SalesManager/Navigation";
import { NavigationMobile } from "./Components/SalesManager/NavigationMobile";
import { Layout } from "./Components/Layout";
import { Home } from "./Components/SalesManager/Home";
import { CartProvider } from "./Components/SalesManager/CartContext";
import { Tenants } from "./Components/SalesManager/Tenants";
import { Stores } from "./Components/SalesManager/Stores";
import UserLogin from "./Components/SalesManager/UserLogin";

function App() {
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // todo: const isMobile = windowDimension <= 640;
  const isMobile = false;

  return (
    <Container fluid>
      {isMobile ? (
        <BrowserRouter>
          <NavigationMobile />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              {/* <Route path="Products" element={<Products />} />
              <Route path="ProductsList" element={<ProductsList />} /> */}
              <Route path="*" element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      ) : (
        <CartProvider>
          <BrowserRouter>
            <Navigation />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<UserLogin />} />
                <Route path="Home" element={<Home />} />
                <Route path="UserLogin" element={<UserLogin />} />
                <Route path="Tenants" element={<Tenants />} />
                <Route path="Stores" element={<Stores />} />
                <Route path="*" element={<UserLogin />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CartProvider>
      )}
    </Container>
  );
}

export default App;
